<template>
  <div id="product-category-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('products.products_categories') }}
      </v-card-title>
      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          md="3"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-fab-transition v-if="hasPermission(50)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="onNew"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.new") }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card> -->

    <v-card>
      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(50)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onNew"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="dataListTable"
        item-key="id"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page')}"
      >
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'views-players-group-form', params: { id: item.name, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.description`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'views-players-group-form', params: { id: item.description, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.description }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(49)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Details</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(51)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(52)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="modalOpen"
      max-width="800"
    >
      <v-card class="user-card-detail pa-sm-10 pa-3">
        <v-card-title class="user-edit-title justify-center text-h5">
          New Product Category
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form
            ref="form"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="nameCategory"
                  :disabled="isFieldsDisabled"
                  outlined
                  dense
                  label="Name"
                  :error-messages="errorMessages.name"
                  placeholder="Name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex align-center"
              >
                <v-select
                  v-model="statusCategory"
                  :disabled="isFieldsDisabled"
                  dense
                  outlined
                  :label="t('status.status')"
                  :items="statusOptions"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex align-center"
                >
                  <v-textarea
                    v-model="descriptionCategory"
                    :disabled="isFieldsDisabled"
                    dense
                    outlined
                    label="Description"
                    placeholder="Description"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-btn
                    color="primary"
                    class="me-3"
                    :disabled="isFieldsDisabled"
                    @click="saveAction"
                  >
                    {{ t('tooltip.save') }}
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="modalOpen =!modalOpen"
                  >
                    {{ t('tooltip.cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { useRouter } from '@core/utils'
import { ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import { postProductCategory, putProductCategory, deleteProductCategory } from '@api'

// sidebar
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import useProductCategoryList from './useProductCategoryList'

export default {
  setup() {
    const { router } = useRouter()
    const { t } = useUtils()
    const modalOpen = ref(false)
    const isFieldsDisabled = ref(false)
    const onEditAction = ref(false)
    const errorMessages = ref([])
    const nameCategory = ref('')
    const idCategory = ref(null)
    const descriptionCategory = ref('')
    const statusCategory = ref({ text: 'Active', value: 'A' })
    const { userData } = useCryptoJs()
    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const {
      dataListTable,
      computedTableColumns,
      totalDataListTable,
      searchQuery,
      facilitiesOptions,
      loading,
      options,
      filters,
      dataTotalLocal,
      configFacility,

      fetchProductsCategories,
      resolveStatusVariant,
      resolveStatusText,
      resolveFacilitiesText,
      hasPermission,
      setFilters,
      setOptions,
    } = useProductCategoryList()

    const onNew = () => {
      router.push({
        name: 'views-products-categories-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
          facility_id: configFacility.value,
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-products-categories-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
          facility_id: configFacility.value,
        },
      })
    }

    const saveAction = async () => {
      if (onEditAction.value === true) {
        await postProductCategory({ facility_id: configFacility.value, name: nameCategory.value, description: descriptionCategory.value, status: statusCategory.value.value })
      } else {
        await putProductCategory({ id: idCategory.value, facility_id: configFacility.value, name: nameCategory.value, description: descriptionCategory.value, status: statusCategory.value.value })
      }
      nameCategory.value = ''
      descriptionCategory.value = ''
      statusCategory.value = { text: 'Active', value: 'A' }
      await fetchProductsCategories()
      modalOpen.value = false
    }

    const onShow = item => {
      router.push({
        name: 'views-products-categories-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteProductCategory(id)
          if (response.ok) {
            success(response.message)
            await fetchProductsCategories()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    return {
      dataListTable,
      computedTableColumns,
      totalDataListTable,
      searchQuery,
      configFacility,
      facilitiesOptions,
      statusOptions,
      loading,
      options,
      filters,
      modalOpen,
      dataTotalLocal,
      errorMessages,
      nameCategory,
      statusCategory,
      descriptionCategory,
      saveAction,
      isFieldsDisabled,
      onEditAction,
      userData,
      onNew,
      idCategory,

      onEdit,
      onShow,
      onDelete,
      resolveStatusVariant,
      resolveStatusText,
      resolveFacilitiesText,
      hasPermission,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
